#accordion{
    margin-top: 20px;
   }
   .questionlbl{
       height: auto;
   }
   .mainDiv{
    height: auto;
    width: 200px;
    display: inline-block;  
}
   .tab{
    .title{
    padding:10px;
    background-color: #cac5c5;
    border: 1px 0 1px 1px;
    border-style: ridge;
    border-color: #dddada;
     height: 80px;
    align-items: center;
    }

    .content{
    padding:20px;
    // border: 1rem 0 1rem 1rem;
    border-style: ridge;
    border-width: thin;
    // border-color: #201d1d;
    height: auto;
    }
    cursor: pointer;
   }
   .flex{
    display:flex;
   }
   .show{
    display:block;
   }
   .hide{
    display:none;
   }
   .jc-btwn{
    justify-content: space-between;
   }
   .spRt{
    float: right;
   }
   .icn {
    display: inline-block;
     padding: 0.1rem 0.5rem 1rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
   }
   .faqGroupLabel {
    font-size: 20px !important;
    text-align: left;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    color: #000;
    width: 30%;
    text-align: center;
    // float: left;
    // padding:20px;
    // text-size-adjust: 17px;
    // font-size: 15px;
    // font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: #cdd7e6;
    border-style: ridge;
    border-width: thin;
    border-color: #e9e2e2;
    height: auto;
   }
