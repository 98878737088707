@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600&display=swap');

body{
    font-family: 'Barlow', sans-serif;
}
.dp-box{
    padding: 0px 10px 10px 10px;
}

.clrChng {
    width: 30px;
    float: left;
}
.clrChng input {
    height: 30px;
    width: 30px;
    border: none !important;
    padding: 0px;
}
.clrTxt {
    width: 100px;
    float: left;
}
.clrTxt input {
    width: 100px;
    height: 30px;
    border-left: none;
    border: 1px solid #ccc;
}

.verical-navs ul li a {
    line-height: initial;
    padding: 10px !important;
    text-align: left;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.verical-navs ul .bg-light {
    /* background: #0660f0 !important; */
    background: #9e9c9c !important;
}
.verical-navs ul .bg-light a {
    color: #fff  !important
}
.verical-navs ul .bg-light a:after{
    bottom: 6px !important;
}
.verical-navs ul .bg-light a:before{
    bottom: 6px !important;
}
.verical-navs ul .bg-light a.active {
    border-left: 5px solid #9e9c9c !important;
    background: #e6e6e6 !important;
    color:#656565 !important;
}
.nav-tabs-navigation #tabs {
    justify-content: left;
    width: 100%;
    background:#0660f0;
}
.nav-tabs-navigation #tabs li a {
    line-height: initial !important;
    padding: 10px 10px 16px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
.nav-tabs-navigation #tabs li a.active:before{
    bottom: -1px;
    display: none;
}
.nav-tabs-navigation #tabs li a.active {
    color: #fff !important;
    background: #064ec1;
}
.nav-tabs-navigation #tabs li a.active:after{
    bottom: -1px;
    display: none;
}
.verical-navs .nav-tabs {
    background: #f7f7f7;
}
.verical-navs ul li {
    border-bottom: 1px solid #e4e4e4;
}
.heding_tab_h3 {
    font-size: 16px !important;
    text-align: left;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    color: #000;
    width: 40%;
    float: left;
}
.smRt {
    text-align: right;
    float: left;
    font-size: 14px !important;
    font-family: 'Barlow', sans-serif;
    width: calc(100% - 40%);
    padding-right: 24px;
}
#vertical-tab-content hr {
    float: left;
    width: 100%;
}
.pd-l0cont {
    padding-left: 0px !important;
}
.designLabel {
    width: 100%;
    float: left;
    text-align: left;
}
.designLabel .text-default {
    font-size: 14px;
}
.smLabl-txt {
    text-align: left !important;
    float: left;
}
.fileinput {
    margin-bottom: 9px;
    display: flex !important;
}
.fileinput .thumbnail {
    max-width: 80px !important;
    margin-right: 10px;
    box-shadow: none !important;
}
.btnRd{
    background: #f52a2a !important;
}
.subHeading_h4 {
    font-size: 15px;
    text-align: left;
    width: 100%;
    float: left;
    margin: 0px;
    color: #676565;
    font-weight: 500;
    background: #f5f5f5;
    padding: 8px;
    margin-bottom: 20px;
}


.designLabel label {
    font-size: 14px !important;
    font-weight: 400;
    color: #252422 !important;
    font-family: 'Barlow', sans-serif;
}
.has-success input {
    font-size: 13px !important;
}
.react-select.primary .react-select__multi-value {
    border: 1px solid #0660f0 !important;
}
.inptFom1 {
    width: 100%;
    float: left;
    margin-bottom: 15px;
}
.inptFom1 .txtarea{
    resize: both;
}
.inptFom {
    width: 100%;
    float: left;
    margin-bottom: 5px;
}
.inptFom .txtarea{
    resize: both;
}
.inptFomColr{
    width: 100%;
    float: left;
    margin-bottom: 15px;
}
#navbarDropdownMenuLink:after {
    display: none;
}
/* .user {
    background: #f1f1f1;
    margin-top: 0px !important;
    padding-top: 20px;
} */
/* .logo:after {
    display: none;
}
.user:after {
    display: none;
} */
.sidebar-wrapper .nav li.active a {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li.active a i {
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li a i{
    font-size: 20px !important;
}
.sidebar-wrapper .nav li:hover a{
    color: #0660f0 !important;
}
.sidebar-wrapper .nav li:hover a i{
    color: #0660f0 !important;
}
.info a span {
    text-transform: uppercase;
    font-family: 'Barlow';
    font-weight: 500;
}
.minBtn button{
    background: #d4d3d3;
}
.minBtn button i {
    color: #7c7e82;
}
.minBtn button:hover, .minBtn button:focus{
    background: #0d61f0 !important;
}
.minBtn button:hover i, .minBtn button:focus i {
    color: #fff !important;
}
.logo-mini{
    display: none !important;
}
.sidebar-mini .logo-mini{
    display: block !important;
}
.logo-normal img {
    width: 120px;
}
