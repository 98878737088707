


.pwd-container {
  position: relative;
  width: auto;
}
.pwd-container input {
  padding: 5px 30px 5px 10px;
  /* font-size: 20px; */
}

.pwd-container i {
  cursor: pointer;
  position: absolute;
  width: 20px;
  right: 8px;
  top: 8px;
}