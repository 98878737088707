
.maindiv {
    height:100%; width:100%; overflow: hidden;
}
.left {
    float: left; width:70%;
}
.center {
    float: left; width:70%;
}

.right {
    float: left; width:25%;
}

.btncancel {
    background-color: #e7e7e7; color: black;
}
.compDiv {
    margin-top: 5px !important;
}
.cancelBtn {
    background-color: rgb(252, 248, 248) !important;
    color: blue !important;
    padding: 5px 10px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 13px !important;
    border: 0.5px solid blue!important;
}
.errLabel {
    color:red !important;
}

.loginBtn {
    background-color: rgb(252, 248, 248) !important;
    color: rgb(45, 45, 49) !important;
    padding: 5px 10px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 13px !important;
    border: 0.5px solid blue!important;
}
.getBtn {
     background-color: rgb(252, 248, 248) !important;
    color: blue !important;
    padding: 5px 10px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 13px !important;
    border: 0.5px solid blue!important;
}