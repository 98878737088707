.planGP{
    float: left;
    display: grid;
    width: 100%;
}
.planGP label.form-check-label{
    float: left;
    margin-right: 10px;
}
.planGP input.form-check-input{
    margin-right: 10px;
}