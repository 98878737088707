
/* ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
} */
.page-number-left{
    float: right;
}
.page-number-list{
    list-style-type: none;
    display: flex;
    padding: 0;
}
.page-number-list li{
    padding: 2px 5px;
}
.form-inputs{
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
.complete{
    font-size: 10px;
}
#completeBtn{
    font-size: 12px;
}