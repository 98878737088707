.iconlst {
    line-height: initial;
    text-align: left;
    margin-bottom: 4px;
}
/* .actionItem {
     display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */
.actionItem {
     display:flex !important;
}
.actionItemdiv {
    width: 120px;
     display:flex !important;
     float:left;
}
.actionItem a {
  /* height: min-content; */
  padding: 7px 10px !important;
}
.pad {
  padding: 7px 10px !important;
}
.btnicon {
  background-color: rgb(7, 126, 238);
  /* border : solid !important ;
    font-size: 8px !important;
    border-radius: 15px !important;
    width: auto;
    cursor: pointer;
    font-size: 10px !important;
    border-radius: 15px !important; */
    
    font-size: 13px !important;
    font-weight: 500;
    font-family: 'Barlow';
    padding: 7px 10px !important;
    text-transform: capitalize !important;
    cursor: pointer;
    width: 140px;
    border-radius: 10px !important;
}
.btnsmicon {
  font-size: 10px !important;
    border-radius: 15px !important; 
    cursor: pointer;
}
.btnsmiconred {
  font-size: 10px !important;
    border-radius: 15px !important;
    
    background-color:rgb(240, 88, 88) !important;
    font-size: 13px !important;
    font-weight: 500;
    font-family: 'Barlow';
    padding: 7px 10px !important;
    text-transform: capitalize !important;
    cursor: pointer;
}

.tricon {
  background-color: rgb(226, 223, 223);
  height: min-content !important;
  border-radius: 33px !important;
}
.tricon td {
  padding: 3px 11px !important;
  align-items: center !important;
}